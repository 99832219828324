import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../assets/simboloVitaResize.png';

const PrivacyPage = () => {
  return (
    <div className='flex w-full justify-center '>
      <nav>
        <AppBar position='fixed'>
          <Toolbar className='flex gap-2 bg-white h-16'>
            <div>
              <img src={logo} alt='vita-logo' className='w-12' />
            </div>
            <div className='flex justify-evenly w-full'>
              {/* <Link
                to='benefits'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold border-white border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Benefícios
              </Link>
              <Link
                to='testimonials'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Depoimentos
              </Link>
              <Link
                to='howItWorks'
                smooth={true}
                duration={500}
                offset={-20}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Como funciona
              </Link> */}
              <RouterLink
                to='/planos'
                // smooth={true}
                // duration={500}
                // offset={30}
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Planos
              </RouterLink>
              {/* <a
                href='https://vita.app.br/blog'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Blog
              </a>
              <Link
                to='/privacidade'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Privacidade
              </Link>
              <Link
                to='/privacidade'
                className='text-black font-semibold hover:border-b-2 hover:border-blue-500 cursor-pointer'
              >
                Login
              </Link> */}
            </div>
          </Toolbar>
        </AppBar>
      </nav>
      <div className='flex flex-col w-4/6 p-8 '>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Política de Privacidade
          </h1>
          <p>Quais dados pessoais coletamos e por que os coletamos</p>
        </div>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Mensagens:
          </h1>
          <p>
            As mensagens que são enviadas contendo suas informações (nome, email
            e telefone) são recebidas no nosso email
            <span className='font-bold'> contato@pranaconsultoria.com</span> e
            servem para fazermos contato a atender você cliente da melhor
            maneira possível. Você pode solicitar um arquivo exportado dos dados
            pessoais que mantemos sobre você, incluindo todos os dados que você
            nos forneceu. Você também pode solicitar que apaguemos todos os
            dados pessoais que mantemos sobre você. Isso não inclui nenhum dado
            que somos obrigados a manter para fins administrativos, legais ou de
            segurança.
          </p>
        </div>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Política de Privacidade
          </h1>
          <p>Quais dados pessoais coletamos e por que os coletamos</p>
        </div>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Cookies
          </h1>
          <div className='flex flex-col gap-2'>
            <p>
              Se você deixar um comentário em nosso site, poderá optar por
              salvar seu nome, endereço de e-mail e site em cookies. Estes são
              para sua conveniência, para que você não precise preencher seus
              dados novamente quando deixar outro comentário. Esses cookies
              duram um ano.
            </p>
            <p>
              Se você visitar nossa página de login, definiremos um cookie
              temporário para determinar se o seu navegador aceita cookies. Este
              cookie não contém dados pessoais e é descartado quando você fecha
              o navegador.
            </p>
            <p>
              Ao fazer login, também configuraremos vários cookies para salvar
              suas informações de login e suas opções de exibição na tela. Os
              cookies de login duram dois dias e os cookies de opções de tela
              duram um ano. Se você selecionar “Lembrar de mim”, seu login
              persistirá por duas semanas. Se você sair da sua conta, os cookies
              de login serão removidos.
            </p>
            <p>
              Se você editar ou publicar um artigo, um cookie adicional será
              salvo no seu navegador. Este cookie não inclui dados pessoais e
              simplesmente indica o ID da postagem do artigo que você acabou de
              editar. Expira após 1 dia.
            </p>
            <p>Conteúdo incorporado de outros sites:</p>
            <p>
              Os artigos deste site podem incluir conteúdo incorporado (por
              exemplo, vídeos, imagens, artigos etc.). O conteúdo incorporado de
              outros sites se comporta exatamente da mesma maneira como se o
              visitante tivesse visitado o outro site.
            </p>
            <p>
              Esses sites podem coletar dados sobre você, usar cookies,
              incorporar rastreamento adicional de terceiros e monitorar sua
              interação com esse conteúdo incorporado, incluindo o rastreamento
              de sua interação com o conteúdo incorporado, se você tiver uma
              conta e estiver logado nesse site.
            </p>
          </div>
        </div>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Por quanto tempo mantemos os dados:
          </h1>
          <p>
            Mantemos os dados por 5 anos, todos mapeados de acordo com a lei
            13.709/2018 que regula a atividade de tratamento de dados no Brasil.
          </p>
        </div>
        <div className='flex flex-col gap-8 pt-20'>
          <h1 className='font-bold text-5xl' style={{ color: '#27E2E7' }}>
            Caso queira exercer o seu direito de titular de dados:
          </h1>
          <div className='flex flex-col gap-4'>
            <p>Solicite através do email contato@vita.app.br</p>
            <p>DPO: Kássio da Silva Policarpo</p>
            <p>Direitos do titular lei 13709/2018:</p>
            <p>I – Confirmação da existência de tratamento;</p>
            <p>II – Acesso aos dados;</p>
            <p>III – Correção dos incompletos inexatos ou desatualizados;</p>
            <p>IV – Eliminação de dados desnecessários</p>
            <p>V – Portabilidade de dados</p>
            <p>VI – Eliminação de dados pessoais tratados com consentimento;</p>
            <p>VII – Informações sobre o compartilhamento de dados;</p>
            <p>VIII – Informação sobre fornecer consentimento;</p>
            <p>IX – Revogação do consentimento.</p>
          </div>
        </div>
        <p className='my-16'>
          O Vita App é um produto desenvolvido a partir das experiências da{' '}
          <a
            href='https://pranaconsultoria.com'
            target='_blank'
            className='cursor-pointer font-bold'
            style={{ color: '#27E2E7' }}
          >
            Prana Vitalidade Empresarial
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
