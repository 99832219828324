import React from 'react';

import {
  Badge,
  Button,
  EditIcon,
  Heading,
  Pane,
  Popover,
  Position,
  Table,
  Text,
} from 'evergreen-ui';

import { convertDate2 } from '../../helpers/dateConverter';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import { useDispatch, useSelector } from 'react-redux';
import { parseStatementDate } from '../../helpers/ofxHelper';

import { v4 as uuidv4 } from 'uuid';

const ReconciliationCheckedPane = ({
  // entryList,
  // statementList,
  handleReconciliation,
}) => {
  const dispatch = useDispatch();
  const { entryList, statementList } = useSelector(
    (state) => state.reconciliationValues
  );

  const handleChecked = (entry) => {
    const tempEntryList = [...entryList];
    dispatch(
      reconciliationValuesActions.setEntryList(
        tempEntryList.filter((tempEntry) => tempEntry.id !== entry.id)
      )
    );
  };

  const handleCheckedStatement = () => {
    dispatch(reconciliationValuesActions.setStatementList([]));
  };

  const isButtonDisabled = () => {
    if (entryList.length === 0 || statementList.length === 0) return true;
    let entryListSum = entryList.reduce(
      (total, entry) => total + entry.valueFinal,
      0
    );
    let statementListSum = statementList.reduce(
      (total, statement) => total + Math.abs(Number(statement.TRNAMT)),
      0
    );
    let difference = entryListSum - statementListSum;
    if (entryListSum > statementListSum) {
      if (difference < 0.0001) {
        difference = 0;
        entryListSum = statementListSum;
      }
    }
    if (entryListSum < statementListSum) {
      if (difference > -0.0001) {
        entryListSum = statementListSum;
        difference = 0;
      }
    }
    console.log('Soma Lançamento', entryListSum);
    console.log('Soma Extrato', statementListSum);
    return entryListSum !== statementListSum;
  };
  const calculateDifference = () => {
    if (entryList.length === 0 || statementList.length === 0) return;
    let entryListSum = entryList.reduce(
      (total, entry) => total + entry.valueFinal,
      0
    );
    let statementListSum = statementList.reduce(
      (total, statement) => total + Math.abs(Number(statement.TRNAMT)),
      0
    );

    if (entryListSum === statementListSum) return;

    let difference = entryListSum - statementListSum;
    if (entryListSum > statementListSum) {
      if (difference < 0.0001) {
        difference = 0;
        entryListSum = statementListSum;
      }
    }
    if (entryListSum < statementListSum) {
      if (difference > -0.0001) {
        entryListSum = statementListSum;
        difference = 0;
      }
    }

    const color = difference < 0 ? 'red' : 'blue';
    const formattedDifference = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Math.abs(difference));

    console.log('Entry list sum', entryListSum);
    console.log('Statement list sum', statementListSum);
    console.log('Difference', difference);
    return (
      <Pane display='flex' justifyContent='space-around' marginTop={12}>
        <Text>
          Diferença: <Text color={color}>{formattedDifference}</Text>
        </Text>
        <Text>
          Diferença:{' '}
          <Text color={difference < 0 ? 'blue' : 'red'}>
            {formattedDifference}
          </Text>
        </Text>
      </Pane>
    );
  };

  return (
    <Pane
      display='flex'
      flexDirection='column'
      marginX={5}
      paddingX={5}
      width='40%'
      // backgroundColor='#c9ccd8'
      justifyContent='space-between'
      borderRadius={10}
    >
      <Pane display='flex'>
        <Pane
          className='vita-pane'
          borderRadius={10}
          width='50%'
          paddingRight={2}
        >
          <Heading marginY={10} textAlign='center'>
            VITA
          </Heading>
          <Pane textAlign='center'>
            <Table>
              <Table.Head height={40}>
                <Table.TextHeaderCell>Data</Table.TextHeaderCell>
                <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
                <Table.TextHeaderCell>Valor</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Pane overflowY='auto' maxHeight={150}>
                  {entryList.length > 0 ? (
                    entryList.map((entry) => {
                      return (
                        <Table.Row
                          key={entry.id}
                          cursor='pointer'
                          onClick={() => handleChecked(entry)}
                          height={50}
                        >
                          <Table.TextCell>
                            {convertDate2(entry.dateVencimento)}
                          </Table.TextCell>
                          <Table.TextCell>
                            {entry.description || '-'}
                          </Table.TextCell>
                          <Table.TextCell>
                            <Text
                              color={
                                entry.journalEntryType === 5
                                  ? 'green'
                                  : entry.isInput
                                  ? 'blue'
                                  : 'red'
                              }
                            >
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(entry.valueFinal)}
                            </Text>
                          </Table.TextCell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row height={50}>
                      <Table.TextCell>
                        <Text>Nenhum lançamento selecionado</Text>
                      </Table.TextCell>
                    </Table.Row>
                  )}
                </Pane>
              </Table.Body>
            </Table>
          </Pane>
        </Pane>

        <Pane
          className='statement-pane'
          borderRadius={10}
          width='50%'
          paddingLeft={2}
        >
          <Heading marginY={10} textAlign='center'>
            EXTRATO
          </Heading>
          <Pane textAlign='center'>
            <Table>
              <Table.Head height={40}>
                <Table.TextHeaderCell>Data</Table.TextHeaderCell>
                <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
                <Table.TextHeaderCell>Valor</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Pane overflowY='auto' maxHeight={150}>
                  {statementList.length > 0 ? (
                    statementList.map((item) => {
                      const uuid = uuidv4();
                      return (
                        <Table.Row
                          key={uuid}
                          cursor='pointer'
                          onClick={handleCheckedStatement}
                          height={50}
                        >
                          <Table.TextCell>
                            {parseStatementDate(item.DTPOSTED)}
                          </Table.TextCell>
                          <Table.TextCell>{item.MEMO}</Table.TextCell>
                          <Table.TextCell>
                            <Text color={item.TRNAMT > 0 ? 'blue' : 'red'}>
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(Math.abs(item.TRNAMT))}
                            </Text>
                          </Table.TextCell>
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row height={50}>
                      <Table.TextCell>
                        <Text>Nenhuma transação selecionada</Text>
                      </Table.TextCell>
                    </Table.Row>
                  )}
                </Pane>
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
      </Pane>
      {calculateDifference()}
      <Pane display='flex' justifyContent='center' marginY={30}>
        <Button
          disabled={isButtonDisabled()}
          size='large'
          onClick={handleReconciliation}
        >
          Conciliar
        </Button>
      </Pane>
    </Pane>
  );
};

export default ReconciliationCheckedPane;
