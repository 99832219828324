import { createSlice } from '@reduxjs/toolkit';

const initialFormState = {
  selectedPlan: '',
  selectedPeriodicity: '',
  selectedPrice: 0,
};

const newCustomerSlice = createSlice({
  name: 'newCustomer',
  initialState: initialFormState,
  reducers: {
    setSelectedPlan(state, action) {
      state.selectedPlan = action.payload;
    },
    setPeriodicity(state, action) {
      state.selectedPeriodicity = action.payload;
    },
    setPrice(state, action) {
      state.selectedPrice = action.payload;
    },
  },
});

export const newCustomerActions = newCustomerSlice.actions;

export default newCustomerSlice.reducer;
