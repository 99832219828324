import axios from 'axios';

const apiClient = axios.create({
  // baseURL: 'https://localhost:5001/api/',
  baseURL: 'https://api.vita.app.br/api',
  // baseURL: 'https://testapi.vita.app.br/api',

  withCredentials: false, // default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = '';
  config.headers['Authorization'] = 'Basic ' + authKey;
  return config;
});

export default apiClient;
