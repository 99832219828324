import { Button, Card, Text } from 'evergreen-ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function SwitchSignInUpComponent({ login, switchCard }) {
  const navigate = useNavigate();
  return (
    <Card
      elevation={4}
      float='left'
      background='white'
      marginX={24}
      padding={16}
      display='flex'
      gap={16}
      height='fit-content'
      alignItems='center'
      justifyContent='space-between'
    >
      {login ? (
        <>
          <Text>Não possui conta?</Text>
          <Button onClick={() => navigate('/planos')}>Registre-se</Button>
        </>
      ) : (
        <>
          <Text>Já possui conta?</Text>
          <Button onClick={switchCard}>Logar-se</Button>
        </>
      )}
      <style jsx='true'>
        {`
          Text {
            margin: 0 16px;
          }
        `}
      </style>
    </Card>
  );
}

export default SwitchSignInUpComponent;
