import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Button } from 'evergreen-ui';
import React from 'react';

const DuplicatedStatementsDialog = ({
  isImportStatementConfirmationDialogOpen,
  handleRemove,
  handleConfirmImport,
}) => {
  return (
    <div>
      <Dialog
        open={isImportStatementConfirmationDialogOpen}
        onClose={handleRemove}
      >
        <DialogContent>
          <DialogTitle>Atenção</DialogTitle>
          <DialogContentText>
            Encontramos um extrato deste banco com a mesma data de início e fim.
            Deseja importar mesmo assim?
          </DialogContentText>
          <DialogActions>
            <Button appearance='primary' intent='danger' onClick={handleRemove}>
              Cancelar
            </Button>
            <Button
              appearance='primary'
              intent='success'
              autoFocus
              onClick={handleConfirmImport}
            >
              Importar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DuplicatedStatementsDialog;
