import { React, useEffect, useState } from 'react';

import {
  Button,
  Card,
  EditIcon,
  Heading,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  SelectField,
  Table,
  Text,
  TickCircleIcon,
  TrashIcon,
} from 'evergreen-ui';

import {
  banksList,
  findOldestAndMostRecentDates,
  parseStatementDate,
  parseStatementDateToDisplay,
} from '../../helpers/ofxHelper';
import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import Filters from './Filters';
import {
  deleteStatement,
  putStatement,
} from '../../services/api/statementService';
import { toastNotification } from '../../shared/toastNotification';
import { mutate } from 'swr';

function ReconciliationFilterBar({
  isLoadingBanks,
  banks,
  statement,
  saveStatement,
  setIsDrawerOpen,
  entriesReconciliation,
}) {
  const dispatch = useDispatch();
  const {
    selectedBank,
    selectedFilter,
    currentStatement,
    statementReconciliatedList,
  } = useSelector((state) => state.reconciliationValues);

  // const handleSelectedFilter = (filter) => {
  //   dispatch(reconciliationValuesActions.setSelectedFilter(filter));
  //   dispatch(reconciliationValuesActions.setStatementList([]));
  //   dispatch(reconciliationValuesActions.setEntryList([]));
  // };

  const handleSelectedBank = () => {
    // dispatch(
    //   reconciliationValuesActions.setCurrentStatement({
    //     ...statement,
    //     bankId: selectedBank,
    //     bankName: banks.data.find((bank) => bank.id === selectedBank)?.name,
    //     isUploaded: false,
    //   })
    // );
    // setStatement((old) => ({
    //   ...old,
    //   bankId: selectedBank,
    //   bankName: banks.data.find((bank) => bank.id === selectedBank)?.name,
    //   isUploaded: false,
    // }));

    saveStatement({
      ...statement,
      bankId: selectedBank,
      bankName: banks.data.find((bank) => bank.id === selectedBank)?.name,
    });
  };

  const handleDeleteStatement = () => {
    if (statementReconciliatedList.length > 0) {
      toastNotification(
        'warning',
        'Não é possível deletar este extrato. Desconcilie os lançamentos e tente novamente.'
      );
      return;
    }
    deleteStatement(currentStatement.id)
      .then((res) => {
        dispatch(reconciliationValuesActions.setCurrentStatement(null));
        dispatch(reconciliationValuesActions.setUploadedStatement(null));
        dispatch(reconciliationValuesActions.setStatementList([]));
        dispatch(reconciliationValuesActions.setEntryList([]));

        toastNotification('success', 'Extrato deletado com sucesso.');
        mutate('statements');
      })
      .catch((err) => {
        toastNotification(
          'warning',
          'Não é possível deletar este extrato. Desconcilie os lançamentos e tente novamente.'
        );
      })
      .finally(() => {});
  };

  const handleCompleteStatement = (mode) => {
    let statusResponse = false;
    let toastContent = '';
    putStatement(currentStatement.id, mode)
      .then((res) => {
        console.log('putStatement', res);
        statusResponse = 'success';
        toastContent = mode
          ? 'Extrato desconciliado com sucesso.'
          : 'Extrato conciliado com sucesso.';
      })
      .catch((err) => {
        statusResponse = 'warning';
        console.log('post err', err);
        toastContent =
          'O extrato não foi atualizado corretamente. Tente novamente.';
      })
      .finally(() => {
        toastNotification(statusResponse, toastContent);
        mutate('statements');
      });
  };

  const tempList = statement.statementObject.data.transactions.map(
    (statement) => {
      return parseStatementDateToDisplay(statement.DTPOSTED);
    }
  );
  const { oldest, mostRecent } = findOldestAndMostRecentDates(tempList);

  return (
    <Pane>
      <Card
        className='cardApiFilter'
        minHeight={50}
        display='flex'
        position='relative'
        alignItems='center'
        gap={100}
        padding={6}
        flexDirection='row'
        flexWrap='wrap'
        width='100%'
        backgroundColor='#f4f5f9'
        justifyContent='center'
        margin='auto'
      >
        <Pane
          display='flex'
          alignItems='center'
          justifySelf='center'
          justifyContent='center'
          width='100%'
        >
          <Pane
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            padding='2px'
          >
            <Pane display='flex' justifyContent='space-between'>
              <Heading marginY={10} size={600}>
                Extrato selecionado
              </Heading>
              <Button onClick={() => setIsDrawerOpen(true)}>
                Painel de Extratos
              </Button>
            </Pane>
            <Pane textAlign='center'>
              <Table>
                <Table.Head paddingRight={0} height={25}>
                  <Table.TextHeaderCell>Período</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Conta Vita</Table.TextHeaderCell>
                  {!statement.isUploaded && (
                    <Table.TextHeaderCell>Filtros</Table.TextHeaderCell>
                  )}
                  <Table.TextHeaderCell>Banco</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Nº Conta</Table.TextHeaderCell>
                  {statement.isUploaded && (
                    <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
                  )}
                  {currentStatement && (
                    <>
                      <Table.TextHeaderCell>Conciliar</Table.TextHeaderCell>
                      <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
                    </>
                  )}
                </Table.Head>
                <Table.Body>
                  <Pane>
                    <Table.Row key={statement.id} height={60}>
                      <Table.TextCell>
                        <Text>
                          {`${oldest.toLocaleDateString()} a ${mostRecent.toLocaleDateString()}`}
                        </Text>
                      </Table.TextCell>

                      {statement.isUploaded ? (
                        <Table.TextCell>
                          <SelectField
                            marginY='auto'
                            onChange={(e) => {
                              dispatch(
                                reconciliationValuesActions.setSelectedBank(
                                  e.target.value
                                )
                              );
                            }}
                          >
                            <option value={null}></option>
                            {!isLoadingBanks &&
                              banks.data
                                .sort((a, b) => {
                                  const nameA = a.name;
                                  const nameB = b.name;

                                  if (nameA < nameB) {
                                    return -1;
                                  }

                                  if (nameA > nameB) {
                                    return 1;
                                  }

                                  return 0;
                                })
                                .map((bank) => {
                                  return (
                                    <option key={bank.id} value={bank.id}>
                                      {bank.name}
                                    </option>
                                  );
                                })}
                          </SelectField>
                        </Table.TextCell>
                      ) : (
                        <Table.TextCell>
                          <Text>{statement.bankName}</Text>
                        </Table.TextCell>
                      )}
                      {!statement.isUploaded && (
                        <Table.TextCell>
                          <Filters />
                        </Table.TextCell>
                      )}
                      <Table.TextCell>
                        <Text>
                          {statement.statementObject.data.signOnMsg.ORG
                            ? statement.statementObject.data.signOnMsg.ORG
                            : banksList.map((bank) => {
                                return bank.Código.includes(
                                  Number(
                                    statement.statementObject.data.statement
                                      .BANKID
                                  ).toString()
                                )
                                  ? bank['Nome da instituição']
                                  : '';
                              })}
                        </Text>
                      </Table.TextCell>
                      <Table.TextCell>
                        <Text>
                          {statement.statementObject.data.statement.ACCTID}
                        </Text>
                      </Table.TextCell>
                      {statement.isUploaded && (
                        <Table.TextCell>
                          <Button
                            disabled={
                              selectedBank === null || selectedBank === ''
                            }
                            onClick={handleSelectedBank}
                          >
                            Salvar Extrato
                          </Button>
                        </Table.TextCell>
                      )}
                      {currentStatement && (
                        <>
                          <Table.TextCell>
                            <Text>
                              {!currentStatement.isConcluded ? (
                                currentStatement.totalStatements -
                                statementReconciliatedList.length
                              ) : (
                                <div className='flex justify-center'>
                                  <TickCircleIcon color='success' />
                                </div>
                              )}
                            </Text>
                          </Table.TextCell>
                          <Table.TextCell>
                            <Pane onClick={(e) => e.stopPropagation()}>
                              <Popover
                                position={Position.BOTTOM_LEFT}
                                animationDuration={1}
                                key={currentStatement.id}
                                content={
                                  <Menu>
                                    <Menu.Group>
                                      <Menu.Item
                                        icon={TrashIcon}
                                        intent='danger'
                                        onClick={handleDeleteStatement}
                                      >
                                        Deletar
                                      </Menu.Item>
                                    </Menu.Group>
                                    <Menu.Group>
                                      <Menu.Item
                                        icon={
                                          !currentStatement.isConcluded
                                            ? TickCircleIcon
                                            : EditIcon
                                        }
                                        intent={
                                          !currentStatement.isConcluded
                                            ? 'success'
                                            : 'warning'
                                        }
                                        onClick={() =>
                                          currentStatement.isConcluded
                                            ? handleCompleteStatement(false)
                                            : handleCompleteStatement(true)
                                        }
                                      >
                                        {!currentStatement.isConcluded
                                          ? 'Concluir Extrato'
                                          : 'Desconciliar Extrato'}
                                      </Menu.Item>
                                    </Menu.Group>
                                  </Menu>
                                }
                              >
                                <IconButton
                                  appearance='minimal'
                                  intent='info'
                                  icon={MoreIcon}
                                />
                              </Popover>
                            </Pane>
                          </Table.TextCell>
                        </>
                      )}
                    </Table.Row>
                  </Pane>
                </Table.Body>
              </Table>
            </Pane>
          </Pane>
        </Pane>
      </Card>
    </Pane>
  );
}

export default ReconciliationFilterBar;
