import React from 'react';

import {
  ArrowBottomRightIcon,
  ArrowTopRightIcon,
  Badge,
  CaretDownIcon,
  CaretUpIcon,
  Dialog,
  DoubleCaretVerticalIcon,
  Heading,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  SwapHorizontalIcon,
  Table,
  Text,
} from 'evergreen-ui';

import {
  parseStatementDate,
  parseStatementDateToDisplay,
  parseStatementDateToForm,
} from '../../helpers/ofxHelper';

import StatementEntry from './StatementEntry';
import { useDispatch, useSelector } from 'react-redux';
import EntryFormStatementComponent from './EntryFormStatementComponent';
import { useState } from 'react';
import TransferFormStatementComponent from './TransferFormStatementComponent';
import { reconciliationValuesActions } from '../../store/reconciliationValues';
import DuplicatedStatementEntry from './DuplicatedStatementEntry';

import { v4 as uuidv4 } from 'uuid';

const ReconciliationStatementsPane = ({
  handleStatementDateSorting,
  statementDateSorting,
  handleStatementValueSorting,
  statementValueSorting,
  // currentStatement,
  entriesReconciliation,
  findDuplicateFitIds,
}) => {
  const [isShownDialog, setIsShownDialog] = useState(false);
  const [isShownTransferDialog, setIsShownTransferDialog] = useState(false);
  const [entryJournalOnFocus, setEntryJournalOnFocus] = useState(null);

  const openDialog = (journalEntry) => {
    setIsShownDialog(true);
    setEntryJournalOnFocus(journalEntry);
  };
  const openTransferDialog = (entry) => {
    setIsShownTransferDialog(true);
    setEntryJournalOnFocus(entry);
  };

  const dispatch = useDispatch();
  const { statementList, currentStatement, selectedFilter } = useSelector(
    (state) => state.reconciliationValues
  );

  const handleSelected = (item) => {
    const tempStatementList = [...statementList];
    if (tempStatementList[0]?.FITID !== item.FITID) {
      dispatch(reconciliationValuesActions.setStatementList([item]));
    } else {
      dispatch(reconciliationValuesActions.setStatementList([]));
    }
  };

  // const openDialog = (journalEntry) => {
  //   setIsShownDialog(true);
  //   setEntryJournalOnFocus(journalEntry);
  // };

  const handleStatementEntries = () => {
    const filteredStatements =
      currentStatement.statementObject.data.transactions

        .filter((item) => {
          // console.log('item', typeof item.TRNAMT);
          item.TRNAMT.replace(',', '.');
          if (selectedFilter === 'todos') {
            return item;
          } else if (selectedFilter === 'entrada') {
            return item.TRNAMT > 0;
          } else if (selectedFilter === 'saida') {
            return item.TRNAMT < 0;
          }
        })
        .filter(
          (item) =>
            entriesReconciliation &&
            !entriesReconciliation.data.some((obj) => obj.fitId === item.FITID)
        )
        .sort((a, b) => {
          if (statementDateSorting === 'asc') {
            return (
              parseStatementDateToDisplay(a.DTPOSTED) -
              parseStatementDateToDisplay(b.DTPOSTED)
            );
          } else if (statementDateSorting === 'desc') {
            return (
              parseStatementDateToDisplay(b.DTPOSTED) -
              parseStatementDateToDisplay(a.DTPOSTED)
            );
          } else {
            return 0;
          }
        })
        .sort((a, b) => {
          if (statementValueSorting === 'asc') {
            return a.TRNAMT - b.TRNAMT;
          } else if (statementValueSorting === 'desc') {
            return b.TRNAMT - a.TRNAMT;
          } else {
            return 0;
          }
        });

    // console.log('filteredStatements', filteredStatements);

    const duplicatedFitIds = findDuplicateFitIds(filteredStatements);

    const finalArray = filteredStatements.map((item) => {
      const uuid = uuidv4();
      if (duplicatedFitIds.includes(item.FITID)) {
        return (
          <DuplicatedStatementEntry
            key={uuid}
            item={item}
            openDialog={openDialog}
            openTransferDialog={openTransferDialog}
          />
        );
      } else {
        return (
          <StatementEntry
            key={uuid}
            item={item}
            openDialog={openDialog}
            openTransferDialog={openTransferDialog}
          />
        );
      }
    });

    if (finalArray.length > 0) return finalArray;
    return (
      <Table.Row height={50}>
        <Table.TextCell>
          <Text size={400}>Nenhuma transação encontrada</Text>
        </Table.TextCell>
      </Table.Row>
    );
  };

  return (
    <Pane className='statement-pane' borderRadius={10} width='30%'>
      <Heading marginY={10} textAlign='center'>
        EXTRATO
      </Heading>
      <Pane textAlign='center'>
        <Table>
          <Table.Head height={40}>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleStatementDateSorting}
                cursor='pointer'
              >
                Data
                {statementDateSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : statementDateSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Descrição</Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Pane
                display='flex'
                alignItems='center'
                justifyContent='center'
                onClick={handleStatementValueSorting}
                cursor='pointer'
              >
                Valor
                {statementValueSorting === 'asc' ? (
                  <CaretUpIcon color='#3366FF' />
                ) : statementValueSorting === 'desc' ? (
                  <CaretDownIcon color='#3366FF' />
                ) : (
                  <DoubleCaretVerticalIcon color='#3366FF' />
                )}
              </Pane>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>Ações</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            <Pane overflowY='scroll' maxHeight={250}>
              <EntryFormStatementComponent
                isShown={isShownDialog}
                setIsShown={setIsShownDialog}
                journalEntry={entryJournalOnFocus}
              />
              <TransferFormStatementComponent
                isShown={isShownTransferDialog}
                setIsShown={setIsShownTransferDialog}
                journalEntry={entryJournalOnFocus}
              />
              {currentStatement && handleStatementEntries()}
            </Pane>
          </Table.Body>
        </Table>
      </Pane>
    </Pane>
  );
};

export default ReconciliationStatementsPane;
