import React from 'react';

import {
  ArrowBottomRightIcon,
  ArrowTopRightIcon,
  IconButton,
  Menu,
  MoreIcon,
  Pane,
  Popover,
  Position,
  SwapHorizontalIcon,
  Table,
  Text,
} from 'evergreen-ui';

import {
  parseStatementDate,
  parseStatementDateToForm,
} from '../../helpers/ofxHelper';

import { useDispatch, useSelector } from 'react-redux';
import { reconciliationValuesActions } from '../../store/reconciliationValues';

const StatementEntry = ({ item, openDialog, openTransferDialog }) => {
  // const [isShownDialog, setIsShownDialog] = useState(false);
  // const [entryJournalOnFocus, setEntryJournalOnFocus] = useState(null);

  const dispatch = useDispatch();
  const { statementList, currentStatement } = useSelector(
    (state) => state.reconciliationValues
  );

  const handleSelected = () => {
    const tempStatementList = [...statementList];
    if (tempStatementList[0]?.FITID !== item.FITID) {
      dispatch(reconciliationValuesActions.setStatementList([item]));
    } else {
      dispatch(reconciliationValuesActions.setStatementList([]));
    }
  };

  // const openDialog = (journalEntry) => {
  //   setIsShownDialog(true);
  //   setEntryJournalOnFocus(journalEntry);
  // };

  return (
    <Table.Row
      key={item.id}
      cursor='pointer'
      height={50}
      className={`statement-entry${
        statementList[0]?.FITID === item.FITID ? '-selected' : ''
      }`}
      onClick={() => handleSelected(item)}
    >
      <Table.TextCell>{parseStatementDate(item.DTPOSTED)}</Table.TextCell>
      <Table.TextCell>{item.MEMO}</Table.TextCell>
      <Table.TextCell>
        <Text color={item.TRNAMT > 0 ? 'blue' : 'red'}>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(Math.abs(item.TRNAMT))}
        </Text>
      </Table.TextCell>
      <Table.TextCell>
        <Pane onClick={(e) => e.stopPropagation()}>
          <Popover
            position={Position.BOTTOM_LEFT}
            animationDuration={1}
            content={
              <Menu>
                <Menu.Group>
                  {item.TRNAMT > 0 ? (
                    <Menu.Item
                      icon={ArrowTopRightIcon}
                      intent='selected'
                      onClick={(e) => {
                        openDialog({
                          journalEntryType: 1,
                          isInput: item.TRNAMT > 0 ? true : false,
                          value: Math.abs(item.TRNAMT),
                          dateVencimento: parseStatementDateToForm(
                            item.DTPOSTED
                          ),
                          dateCompetencia: parseStatementDateToForm(
                            item.DTPOSTED
                          ),
                          fitId: item.FITID,
                          bankAccountId: currentStatement.bankId,
                        });
                        e.stopPropagation();
                      }}
                    >
                      Entrada
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      icon={ArrowBottomRightIcon}
                      intent='danger'
                      onClick={(e) => {
                        openDialog({
                          journalEntryType: 1,
                          isInput: item.TRNAMT > 0 ? true : false,
                          value: Math.abs(item.TRNAMT),
                          dateVencimento: parseStatementDateToForm(
                            item.DTPOSTED
                          ),
                          dateCompetencia: parseStatementDateToForm(
                            item.DTPOSTED
                          ),
                          fitId: item.FITID,
                          bankAccountId: currentStatement.bankId,
                        });
                        e.stopPropagation();
                      }}
                    >
                      Saída
                    </Menu.Item>
                  )}
                  <Menu.Item
                    icon={SwapHorizontalIcon}
                    intent='success'
                    // secondaryText=" • "
                    onClick={() => {
                      openTransferDialog({
                        journalEntryType: 5,
                        isInput: item.TRNAMT > 0 ? true : false,
                        value: Math.abs(item.TRNAMT),
                        dateVencimento: parseStatementDateToForm(item.DTPOSTED),
                        dateCompetencia: parseStatementDateToForm(
                          item.DTPOSTED
                        ),
                        fitId: item.FITID,
                        bankAccountId:
                          item.TRNAMT > 0 ? undefined : currentStatement.bankId,
                        bankAccountIdDestination:
                          item.TRNAMT > 0 ? currentStatement.bankId : undefined,
                      });
                    }}
                  >
                    Transferência
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            }
          >
            <IconButton appearance='minimal' intent='info' icon={MoreIcon} />
          </Popover>
        </Pane>
      </Table.TextCell>
    </Table.Row>
  );
};

export default StatementEntry;
