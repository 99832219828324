import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  const navigate = useNavigate();
  return (
    <div className='w-full flex flex-col items-center p-20 gap-4 text-center'>
      <div className='flex gap-1 mb-8'>
        <h1 className='text-blue-400 font-bold text-5xl'>Parabéns!</h1>
      </div>
      <div className='flex gap-1 font-bold mb-8'>
        <h1 className='text-2xl'>Seu cadastro foi concluído com sucesso.</h1>
      </div>
      <h1 className='text-xl'>
        Em breve você receberá um e-mail com as instruções para efetuar o
        pagamento da sua assinatura.
      </h1>
      <h1 className='text-xl mb-8'>
        Caso não encontre o e-mail,{' '}
        <strong className='font-semibold'>verifique sua caixa de spam.</strong>
      </h1>
      <Button variant='contained' color='primary' onClick={() => navigate('/')}>
        Login
      </Button>
    </div>
  );
};

export default ThankYouPage;
